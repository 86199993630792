import React from "react"
import CartWrapper from "../components/bigcommerce/CartWrapper"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CartPage = () => (
  <Layout>
    <SEO title="Cart" />
    <div className="page-headline">
      <div className="container">
        <div className="section-heading mb-0">
          <CartWrapper cartType="full" />
        </div>
      </div>
    </div>
  </Layout>
)

export default CartPage
